<template>
	<div class="checkout-header">
		<div class="text-h4 header-text">
			<v-img contain max-width="124" src="@/assets/owo-peek.png"></v-img>
			<div v-if="!isMobile" class="checkout-text">Payment Information</div>
			<div class="authnet">
				<!-- (c) 2005, 2022. Authorize.Net is a registered trademark of CyberSource Corporation -->
				<a
					:href="authNetHref"
					onmouseover="window.status='http://www.authorize.net/'; return true;"
					onmouseout="window.status=''; return true;"
					:onclick="authNetClick"
					rel="noopener noreferrer"
					target="_blank"
				>
					<img
						src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
						width="90"
						height="72"
						border="0"
						alt="Authorize.Net Merchant - Click to Verify"
					/>
				</a>
			</div>
		</div>
		<div v-if="isMobile" class="text-h4">Payment Information</div>
		<v-breadcrumbs :items="breadcrumbs" class="checkout-breadcrumbs">
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<script>
const url = process.env.VUE_APP_FRONTEND;

export default {
	name: 'CheckoutHeader',
	data: () => ({
		breadcrumbs: [
			{
				text: 'Cart',
				disabled: false,
				href: '/store',
			},
			{
				text: 'Information',
				disabled: false,
				href: '/checkout',
			},
			{
				text: 'Payment',
				disabled: true,
				href: '/checkout',
			},
		],
		authNetHref: `https://verify.authorize.net/anetseal/?pid=7f8fa336-5088-4937-9d3f-84594bcd29eb&amp;rurl=${url}`,
		authNetClick: `window.open('https://verify.authorize.net/anetseal/?pid=7f8fa336-5088-4937-9d3f-84594bcd29eb&amp;rurl=${url}','AuthorizeNetVerification','width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes'); return false;`,
	}),
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 550;
		},
	},
};
</script>

<style scoped>
.checkout-header {
	display: flex;
	width: 100%;
	align-items: flex-start;
	flex-direction: column;
}

.header-text {
	display: flex;
	align-items: bottom;
	width: 100%;
}

.checkout-breadcrumbs {
	padding-left: 0px;
	padding-top: 10px;
}

.checkout-text {
	padding-left: 10px;
	height: min-content;
	align-self: end;
}

.authnet {
	margin-left: auto;
	margin-bottom: -10px;
}
</style>
