<template>
	<div>
		<GooglePayButton
			id="google-pay-btn"
			:options="options"
			@payed="payed"
			@cancel="cancel"
		/>
	</div>
</template>

<script>
import GooglePayButton from '@/components/googlepay/GooglePayButton';

export default {
	name: 'CheckoutOptions',
	components: {
		GooglePayButton,
	},
	data: () => ({
		options: {
			environment: process.env.VUE_APP_GPAY_ENV,
			buttonColor: 'white',
			buttonType: 'checkout',
			allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
			allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
			baseCardPaymentMethod: {
				type: 'CARD',
				parameters: {
					allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
					allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA'],
					billingAddressRequired: true,
					billingAddressParameters: {
						format: 'MIN',
					},
				},
			},
			merchantInfo: {
				merchantName: 'OWO Bot',
				merchantId: 'BCR2DN4TUD24ZLQK',
			},
			transactionInfo: {
				totalPriceStatus: 'FINAL',
				totalPrice: null,
				currencyCode: 'USD',
				countryCode: 'US',
			},
			tokenizationSpecification: {
				type: 'PAYMENT_GATEWAY',
				parameters: {
					gateway: 'authorizenet',
					gatewayMerchantId: process.env.VUE_APP_AUTHORIZENET_GATEWAY_ID,
				},
			},
		},
	}),
	methods: {
		async payed(result) {
			if (!result?.paymentMethodData?.tokenizationData) {
				const opt = {
					text: 'Google Pay Failed! Please try again.',
					width: 500,
					imgUrl: 'owo-cry.png',
					buttons: [
						{
							text: 'Ok :(',
							color: 'primary',
						},
					],
				};
				return this.$modal(opt).showError();
			}
			const token = result.paymentMethodData.tokenizationData.token;
			const enc = window.btoa(token);
			await this.$store.dispatch('setPaymentNonce', {
				paymentNonce: {
					dataDescriptor: 'COMMON.GOOGLE.INAPP.PAYMENT',
					dataValue: enc,
				},
				paymentType: 'GOOGLE PAY',
				fourDigits: result.paymentMethodData.info.cardDetails,
				cardName: result.paymentMethodData.info.billingAddress.name,
				zipcode: result.paymentMethodData.info.billingAddress.postalCode,
			});

			this.$router.push(`/confirmation`);
		},
		cancel(err) {
			console.error(err);
			const opt = {
				text: 'Google Pay Failed! Please try again.',
				width: 500,
				imgUrl: 'owo-cry.png',
				buttons: [
					{
						text: 'Ok :(',
						color: 'primary',
					},
				],
			};
			this.$modal(opt).showError();
		},
	},
	mounted() {
		this.options.transactionInfo.totalPrice =
			this.$store.getters.totalCost.toString();
	},
};
</script>

<style scoped></style>
