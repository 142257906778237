<template>
	<div :class="isMobile ? 'checkout-mobile' : 'checkout'">
		<CheckoutForm />
		<CheckoutCart />
		<CheckoutHeader v-if="isMobile" />
	</div>
</template>

<script>
import CheckoutForm from '@/views/checkout/components/CheckoutForm.vue';
import CheckoutCart from '@/views/checkout/components/CheckoutCart.vue';
import CheckoutHeader from '@/views/checkout/components/CheckoutHeader.vue';

export default {
	name: 'CheckoutPage',
	components: {
		CheckoutForm,
		CheckoutCart,
		CheckoutHeader,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 1020;
		},
	},
	mounted() {
		this.$store.dispatch('clearPaymentNonce');
		const total = this.$store.getters.totalCost;
		if (!total || total < 3) {
			this.$router.back();
		}
	},
};
</script>

<style scoped>
.checkout {
	display: flex;
	max-width: 100%;
	width: 100vw;
	overflow-x: hidden;
}

.checkout-mobile {
	padding: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column-reverse;
}
</style>
