<template>
	<div class="cc">
		<v-card color="background" class="cc-card" flat>
			<v-form ref="form" class="ma-2 card-form" lazy-validation>
				<div class="card-display-center">
					<VuePaycard
						class="card-display"
						:value-fields="valueFields"
						:input-fields="inputFields"
					/>
				</div>
				<v-container class="pt-7">
					<v-row>
						<v-col>
							<v-text-field
								v-model="valueFields.cardNumber"
								:id="inputFields.cardNumber"
								class="remove-bot-padding"
								label="Card Number"
								data-card-field
								:maxLength="cardNumberMaxLength"
								@input="changeNumber"
								type="tel"
								autocomplete="cc-number"
								name="cc-number"
								x-autocompletetype="cc-number"
								:rules="cardRules"
								outlined
								dense
								required
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field
								v-model="valueFields.cardName"
								:id="inputFields.cardName"
								type="text"
								label="Card Holder"
								class="remove-bot-padding"
								data-card-field
								autocomplete="cc-name"
								name="cc-name"
								x-autocompletetype="cc-name"
								:rules="nameRules"
								outlined
								dense
								required
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-select
								v-model="valueFields.cardMonth"
								:id="inputFields.cardMonth"
								:items="months"
								class="remove-bot-padding"
								label="Month"
								:rules="dateRules"
								data-card-field
								autocomplete="cc-exp-month"
								name="cc-exp-month"
								x-autocompletetype="cc-exp-month"
								outlined
								required
								dense
								ref="ccMonth"
								@hook:mounted="ccMonthMounted"
							></v-select>
						</v-col>
						<v-col>
							<v-select
								v-model="valueFields.cardYear"
								:id="inputFields.cardYear"
								data-card-field
								class="remove-bot-padding"
								:items="years"
								:rules="dateRules"
								autocomplete="cc-exp-year"
								name="cc-exp-year"
								x-autocompletetype="cc-exp-year"
								label="Year"
								outlined
								required
								dense
								ref="ccYear"
								@hook:mounted="ccYearMounted"
							></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field
								v-model="valueFields.cardZipcode"
								:id="inputFields.cardCZipcode"
								class="remove-bot-padding"
								maxLength="20"
								data-card-field
								autocomplete="postal-code"
								name="postal-code"
								x-autocompletetype="postal-code"
								type="tel"
								label="Zipcode"
								:rules="zipRules"
								outlined
								required
								dense
							></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-text-field
								v-model="valueFields.cardCvv"
								:id="inputFields.cardCvv"
								class="remove-bot-padding"
								maxLength="4"
								data-card-field
								autocomplete="cc-csc"
								name="cc-csc"
								x-autocompletetype="cc-csc"
								type="tel"
								label="CVV"
								:rules="cvvRules"
								outlined
								dense
								required
							></v-text-field>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
			<v-card-actions class="cc-buttons">
				<v-btn class="cc-button" height="45" min-width="100" @click="goBack">
					Back
				</v-btn>
				<v-spacer />
				<v-btn
					class="cc-button"
					height="45"
					min-width="100"
					color="success"
					@click="payButton"
					:loading="disablePay"
				>
					Pay ${{ price }}.00
				</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import { VuePaycard } from 'vue-paycard';

export default {
	name: 'CheckoutCCModal',
	components: {
		VuePaycard,
	},
	data: () => ({
		disablePay: false,
		valid: false,
		minCardYear: new Date().getFullYear(),
		mainCardNumber: '',
		cardNumberMaxLength: 19,
		cardRules: [
			(v) => !!v || 'Cannot be blank',
			(v) => !v || /^-?\d+$/.test(v.replaceAll(' ', '')) || 'Invalid Number',
		],
		nameRules: [
			(v) => !!v || 'Cannot be blank',
			(v) => v.length <= 64 || 'Name is too long',
		],
		cvvRules: [
			(v) => !!v || 'Cannot be blank',
			(v) => ('' + v).length > 2 || 'CVV is too short',
			(v) => /^-?\d+$/.test(v) || 'Invalid CVV',
		],
		dateRules: [(v) => !!v || 'Cannot be blank'],
		zipRules: [
			(v) => !!v || 'Cannot be blank',
			(v) => ('' + v).length > 2 || 'Zipcode is too short',
			(v) => /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(v) || 'Invalid zipcode',
		],
		valueFields: {
			cardName: '',
			cardNumber: '',
			cardMonth: '',
			cardYear: '',
			cardCvv: '',
			cardZipcode: '',
		},
		inputFields: {
			cardNumber: 'cc-number',
			cardName: 'cc-name',
			cardMonth: 'cc-exp-month',
			cardYear: 'cc-exp-year',
			cardCvv: 'cc-csc',
			cardZipcode: 'postal-code',
		},
		months: [],
		years: [],
	}),
	computed: {
		minCardMonth() {
			if (this.valueFields.cardYear === this.minCardYear)
				return new Date().getMonth() + 1;
			return 1;
		},
		price() {
			return this.$store.getters.totalCost;
		},
	},
	methods: {
		goBack() {
			this.$router.push('/store');
		},
		async payButton() {
			if (this.$refs.form.validate()) {
				this.disablePay = true;
				let data;
				try {
					data = await this.$store.dispatch('sendCC', {
						cardName: this.valueFields.cardName.trim(),
						cardNumber: this.valueFields.cardNumber.replaceAll(/\s/gi, ''),
						cardMonth: this.valueFields.cardMonth.replaceAll(/\s/gi, ''),
						cardYear: ('' + this.valueFields.cardYear).slice(-2),
						cardCvv: this.valueFields.cardCvv,
						cardZipcode: this.valueFields.cardZipcode,
					});
				} catch (err) {
					console.error(err);
					const errorText =
						err.resultCode === 'Error'
							? err.message[0]?.text
							: 'Payment failed, please try a different credit card.';

					const opt = {
						text: errorText,
						width: 500,
						imgUrl: 'owo-cry.png',
						buttons: [
							{
								text: 'Ok :(',
								color: 'primary',
							},
						],
					};
					await this.$modal(opt).showError();
					this.disablePay = false;
					return;
				} finally {
					this.disablePay = false;
				}
				await this.$store.dispatch('setPaymentNonce', {
					paymentNonce: data,
					paymentType: 'CREDIT CARD',
					fourDigits: this.valueFields.cardNumber
						.replaceAll(/\s/gi, '')
						.slice(-4),
					cardName: this.valueFields.cardName,
					zipcode: this.valueFields.cardZipcode,
				});

				this.$router.push(`/confirmation`);
			}
		},
		changeNumber(e) {
			this.valueFields.cardNumber = e;
			const value = this.valueFields.cardNumber.replace(/\D/g, '');
			// american express, 15 digits
			if (/^3[47]\d{0,13}$/.test(value)) {
				this.valueFields.cardNumber = value
					.replace(/(\d{4})/, '$1 ')
					.replace(/(\d{4}) (\d{6})/, '$1 $2 ');
				this.cardNumberMaxLength = 17;
			} else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
				// diner's club, 14 digits
				this.valueFields.cardNumber = value
					.replace(/(\d{4})/, '$1 ')
					.replace(/(\d{4}) (\d{6})/, '$1 $2 ');
				this.cardNumberMaxLength = 16;
			} else if (/^62[0-9]\d*/.test(value)) {
				this.valueFields.cardNumber = value
					.replace(/(\d{6})/, '$1 ')
					.replace(/(\d{6}) (\d{7})/, '$1 $2 ')
					.replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ')
					.replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4');
				this.cardNumberMaxLength = 21;
			} else if (/^\d{0,16}$/.test(value)) {
				// regular cc number, 16 digits
				this.valueFields.cardNumber = value
					.replace(/(\d{4})/, '$1 ')
					.replace(/(\d{4}) (\d{4})/, '$1 $2 ')
					.replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
				this.cardNumberMaxLength = 19;
			}
			// eslint-disable-next-line
      if (e.inputType == 'deleteContentBackward') {
				const lastChar = this.valueFields.cardNumber.substring(
					this.valueFields.cardNumber.length,
					this.valueFields.cardNumber.length - 1
				);
				// eslint-disable-next-line
        if (lastChar == ' ') { this.valueFields.cardNumber = this.valueFields.cardNumber.substring(0, this.valueFields.cardNumber.length - 1) }
			}
		},
		maskCardNumber() {
			this.valueFields.cardNumberNotMask = this.valueFields.cardNumber;
			this.mainCardNumber = this.valueFields.cardNumber;
			const arr = this.valueFields.cardNumber.split('');
			arr.forEach((element, index) => {
				if (index > 4 && index < 14 && element.trim() !== '') {
					arr[index] = '*';
				}
			});
			this.valueFields.cardNumber = arr.join('');
		},
		unMaskCardNumber() {
			this.valueFields.cardNumber = this.mainCardNumber;
		},
		ccMonthMounted() {
			const input = this.$refs.ccMonth.$el.querySelector(
				'.v-select__selections input'
			);
			input.removeAttribute('readonly');
		},
		ccYearMounted() {
			const input = this.$refs.ccYear.$el.querySelector(
				'.v-select__selections input'
			);
			input.removeAttribute('readonly');
		},
	},
	mounted() {
		let currentYear = this.minCardYear;
		for (let i = 1; i <= 12; i++) {
			this.months.push(i < 10 ? '0' + i : '' + i);
			this.years.push(currentYear);
			currentYear++;
		}
	},
};
</script>

<style scoped>
.cc-card {
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow: display;
	justify-content: space-between;
	height: 100%;
}

.card-display {
	width: 100%;
	margin-bottom: 40px;
	min-width: 407px;
	padding-right: 20px;
	padding-left: 20px;
}

.card-form {
	padding-top: 30px;
	width: 80%;
	max-width: 800px;
}

.remove-bot-padding {
	margin-bottom: -20px;
}

.cc {
	flex-grow: 1;
}

.cc-buttons {
	width: 100%;
	padding: 0px;
}

.cc-button {
	width: 20%;
	max-width: 200px;
}

.card-display-center {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
</style>
