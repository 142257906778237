<template>
	<div :class="isMobile ? 'checkoutform-mobile' : 'checkoutform'">
		<CheckoutHeader v-if="!isMobile" />
		<CheckoutOptions />
		<CheckoutCC />
	</div>
</template>

<script>
import CheckoutCC from '@/views/checkout/components/CheckoutCC.vue';
import CheckoutHeader from '@/views/checkout/components/CheckoutHeader.vue';
import CheckoutOptions from '@/views/checkout/components/CheckoutOptions.vue';

export default {
	name: 'CheckoutForm',
	components: {
		CheckoutCC,
		CheckoutHeader,
		CheckoutOptions,
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.width < 1020;
		},
	},
	mounted() {},
};
</script>

<style scoped>
.checkoutform {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	flex-grow: 1;
	padding: 50px 100px;
}

.checkoutform-mobile {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	flex-grow: 1;
	padding-top: 20px;
}
</style>
