<template>
	<div class="checkout-options">
		<CheckoutGpay />
		<div class="divider">
			<v-divider></v-divider>
			<div class="text-h6 or-text">or</div>
			<v-divider></v-divider>
		</div>
		<div class="options-cc">
			<v-img
				alt="visa"
				src="@/assets/cc/visa.png"
				contain
				height="64"
				width="64"
			/>
			<v-img
				alt="discover"
				src="@/assets/cc/discover.png"
				contain
				height="64"
				width="64"
			/>
			<v-img
				alt="american_express"
				src="@/assets/cc/american_express.png"
				contain
				height="64"
				width="64"
			/>
			<v-img
				alt="mastercard"
				src="@/assets/cc/mastercard.png"
				contain
				height="64"
				width="64"
			/>
		</div>
	</div>
</template>

<script>
import CheckoutGpay from '@/views/checkout/components/CheckoutGpay.vue';

export default {
	name: 'CheckoutOptions',
	components: {
		CheckoutGpay,
	},
};
</script>

<style scoped>
.checkout-options {
	padding-top: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.divider {
	padding-top: 40px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.or-text {
	padding: 0px 20px;
}

.options-cc {
	padding-top: 40px;
	padding-bottom: 30px;
	display: flex;
	gap: 10px;
}
</style>
